import constants from "../utils/constants_epics";
import { store } from "../redux";
import avro from "avsc/etc/browser/avsc-types";
import avro_scheme from "../avro-scheme/match.json";
import { Buffer } from 'buffer';


export default class SocketUtil {
  constructor() {
    this.socket = null;
    this.counter = 0;
    this.interval = null;
  
  }

  init() {
    this.connect();
  }

  connect() {
    const socketUrl = `wss://btrd.rhk4044${constants.base_url.replace(
      "https://api.ibaterm",
      ""
    )}/wsgo`;
    this.socket = new WebSocket(socketUrl);
    this.socket.binaryType = "arraybuffer";

    this.socket.onopen = (evt) => {
      this.onOpen(evt);
    };
    this.socket.onclose = (evt) => {
      this.onClose(evt);
    };
    this.socket.onmessage = (evt) => {
      this.onMessage(evt);
    };
    this.socket.onerror = (evt) => {
      this.onError(evt);
    };
  }

  reset() {
    if (this.socket) {
      this.socket.close();
      this.connect();
    }
  }

  onOpen(evt) {
    this.timer();
    // console.log(evt)
  }

  onClose(evt) {
    this.clearTimer();
    // console.log(evt)
  }

  onMessage(evt) {
   // console.log(evt.data)
    const schema = avro.parse(avro_scheme)
    const match = schema.fromBuffer(Buffer.from(evt.data, 'binary'));
    this.processData(match);
  //  console.log(match)
    try {
      this.timer();
     
      const _m = this._scheme.fromBuffer(Buffer.from(evt.data, "binary"));
    //  console.log(_m)
      this.processData(_m);
    } catch (error) {}
  }

  onError(evt) {
     console.log("on error")
     console.log(evt)
  }

  disconnect() {
    this.socket.close();
  }

  processData(f) {
  //  console.log(f)
    try {
      let d = store.getState().live_matches;
    //   console.warn("message comming", { f, d });
      let e = d?.filter((x) => x.id == f?.matchid);
   //    console.log("test scenario e", e);
      if (e?.length > 0) {
        e = e[0];
        e.update(f);
      }
    } catch {}
  }

  timer() {
    this.counter = 0;
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      let a = this.counter;
      this.counter = a + 1;
    //   console.log(this.counter)
      if (this.counter == 20) this.reset();
    }, 1000);
  }

  clearTimer() {
    if (this.interval) clearInterval(this.interval);
  }
}
